import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function BasicModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      centered
      data-bs-theme="dark"
    >
      <Modal.Body>
        <img src={props.body}  style={{width: '100%' }}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} style= {{backgroundColor: '#dfc15e', color: 'black', fontWeight: 'bold', border: '0'}}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  );
}