import React from "react";
import './App.css';
import { Homepage } from './containers/homepage'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import BasicModal from "./components/modal"

function App(props) {
  const [modalShow, setModalShow] = React.useState(true);
  return (
  <>
    <BasicModal show={modalShow} onHide={() => setModalShow(false)} body={"/picture/popup.jpg"}/>
    <Homepage />
  </>
  )
}

export default App;
